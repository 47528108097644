<template>
  <div class="nft container">
    <div class="title">{{ $t('news.miner') }}</div>
    <ul>
      <li v-for="(data,index) in datas" :key="index">
        <div class="name" v-if="data.minerid==1">{{ $t('news.minerid1') }}</div>
        <div class="name" v-else-if="data.minerid==2">{{ $t('news.minerid2') }}</div>
        <div class="name" v-else-if="data.minerid==3">{{ $t('news.minerid3') }}</div>
        <div class="name" v-else-if="data.minerid==4">{{ $t('news.minerid4') }}</div>
        <div class="state">
          <span>x {{data.number}}</span>
          <img src="../../assets/img/angel-1.png">
        </div>
        <div  style="text-align: center">
          {{ $t('news.nclaimed')}}:{{data.haveamount}} WF314
        </div>
        <div style="display: flex">
          <div class="btn-transf"  @click="handleActiveDialog(data)">
            {{ $t('app.mine.transfer') }}
          </div>
          <div class="btn-transf" @click="getEnvoy(data)"  v-if="data.haveamount>0">
            {{ $t('news.receive')}}
          </div>
          <div class="btn-transf1"  v-else>
            {{ $t('news.receive')}}
          </div>
        </div>
        <div style="height: 30px"></div>

      </li>
    </ul>

    <el-dialog :visible.sync="dialogVisible" width="6rem" @closed="handleDialogClose">
      <div class="dialog-content">
        <div class="dialog-title">{{ $t('app.mine.transfer') }}</div>
        <input type="text" v-model="targetAddress" :placeholder="$t('news.address')">
        <input type="number" v-model="number" :placeholder="$t('news.number')">
        <div class="btn-dialog" @click="handleTrans">{{ $t('app.mine.transfer') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ethers } from 'ethers'
// import Community from '../../contracts/Community.json'
import { initSigner } from '../../utlis/index'
import WFMiner from "../../contracts/WFMiner.json"
import {get, post} from "@/utlis/axios";

export default {
  data() {
    return {
      address: '',
      contracts: {},
      ispledge1:'',
      ispledge2:'',
      datas:[],
      dialogVisible:false,
      targetAddress:'',
      number:'',
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  methods: {
    handleActiveDialog(item) {
      this.chooseItem = item
      this.dialogVisible = true
    },
    handleTrans() {
      this.contracts.Miner.transfer(this.targetAddress,this.chooseItem.minerid,this.number).then(async (result) => {
        await result.wait()
        this.dialogVisible = false
        this.getData();
        this.$message.success("转让成功")

      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.receiveSB'))
      });
    },
    handleDialogClose() {
      this.chooseItem = {}
      this.targetAddress = ''
    },
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address

      get('/getMyMiner', { address: this.address}).then(res => {
        console.log(res);
        this.datas = res;

      }).catch(e => {
        console.error(e);
      })
      // this.contracts.Community = new ethers.Contract(this.$common.Community, Community, provider).connect(signer)
      this.contracts.Miner = new ethers.Contract(this.$common.Miner, WFMiner, provider).connect(signer)

      this.getData()
      window.contracts = this.contracts;
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData() {
      if (!this.address) return false

      get('/getMyMiner', { address: this.address}).then(res => {
        console.log(res);
        this.datas = res;

      }).catch(e => {
        console.error(e);
      })
    },


    getEnvoy(item) {
      // if (this.EnvoyAward <= 0) return this.$message.error(this.$t('app.cooperation.No'))
      post('/getMinerReward', { address: this.address,userMinerId:item.minerid}).then(res => {
        console.log(res.haveamount,item.minerid,res.sign);
        this.contracts.Miner.withdraw(res.haveamount,item.minerid,res.sign).then(async (result) => {
          await result.wait()
          setTimeout(function () {
            this.getData();
          },3000)
          this.dialogVisible = false

          this.$message.success(this.$t('app.cooperation.receiveCG'))

        }).catch((err) => {
          console.log(err);
          this.$message.error(this.$t('app.cooperation.receiveSB'))
        });
      }).catch(e => {
        console.error(e);
      })

      // this.contracts.EnvoyBoard.getReward().then(async (result) => {
      //   await result.wait()
      //   this.$message.success(this.$t('app.cooperation.receiveCG'))
      //   this.getData()
      // }).catch((err) => {
      //   console.log(err);
      //   this.$message.error(this.$t('app.cooperation.receiveSB'))
      // });
    },


  }
}
</script>

<style lang="scss" scoped>
.nft {
  .dialog-content {
    font-size: 00.3rem;
    color: #fff;
    .dialog-title {
      color: #45bbf3;
      font-size: 0.36rem;
      margin-bottom: 0.5rem;
      text-align: center;
    }
    input {
      width: 100%;
      // height: 0.84rem;
      padding: 0.3rem 0.2rem;
      font-size: 0.24rem;
      margin-bottom: 0.2rem;
      background-color: rgba(85, 114, 241, 0.5);
      border: 1px solid #45bbf3;
      box-sizing: border-box;
      color: #fff;
    }
    .btn-dialog {
      height: 0.9rem;
      line-height: 0.9rem;
      font-size: 0.28rem;
      background-color: rgba(178, 133, 237, 0.8);
      box-sizing: border-box;
      border: 1px solid #45bbf3;
      text-align: center;
      margin-top: 0.25rem;
      position: relative;
      // &::after {
      //   content: ' ';
      //   position: absolute;
      //   left: 0.08rem;
      //   top: 0.08rem;
      //   width: 100%;
      //   height: 100%;
      //   border: 1px solid #B285ED;
      // }
    }
  }
  padding: 1.4rem 0.44rem 0.4rem;
  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url("../../assets/img/web-title-bg.png") left top / 100% 100%
      no-repeat;
  }
  ul {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2;    /* Firefox */
    column-count: 2;
    /* 可选的样式 */
    -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
    -moz-column-gap: 20px;    /* Firefox */
    column-gap: 20px;
    /* 内边距可以根据需要添加 */
    padding: 20px;
    //align-items: flex-start;
    //justify-content: space-between;
    li {
      width: 100%;
      text-align: center;
      font-size: 0.24rem;
      .name {
        width: 2.32rem;
        height: 0.62rem;
        line-height: 0.62rem;
        margin: 0 auto 0.2rem;
        text-align: center;
        background: url("../../assets/img/crowd-btn.png") left top / 100% 100%
          no-repeat;
      }
      .state {
        position: relative;
        span {
          position: absolute;
          bottom: 0.2rem;
          left: 50%;
          transform: translateX(-50%);
          font-size: 0.24rem;
          white-space: nowrap;
        }
        img {
          width: 2.98rem;
          height: 3.9rem;
          margin: 0 auto 0.1rem;
        }
      }
      .tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.2rem;
      }
      .btn{
        display: flex;
        justify-content: space-between;
      }
      .btn-receive {
        width: 2.8rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 00.28rem;
        background-color: rgba(178, 133, 237, 0.8);
        border: 1px solid #45bbf3;
        border-radius: 0.08rem;
        color: #fff;

      }
      .btn-transf {
        display: inline-block;
        margin-left: 10px;
        width: 1.4rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.3rem;
        background-color: rgba(85, 114, 241, 0.8);
        box-sizing: border-box;
        border: 1px solid #45bbf3;
        text-align: center;
        margin-top: 0.25rem;
        position: relative;
        // &::after {
        //   content: ' ';
        //   position: absolute;
        //   left: 0.08rem;
        //   top: 0.08rem;
        //   width: 100%;
        //   height: 100%;
        //   border: 1px solid #B285ED;
        // }
      }
      .btn-transf1 {
        display: inline-block;
        width: 1.4rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.3rem;
        margin-left: 10px;
        background-color: rgba(189, 189, 189, 0.8);
        box-sizing: border-box;
        border: 1px solid #45bbf3;
        text-align: center;
        margin-top: 0.25rem;
        position: relative;
        // &::after {
        //   content: ' ';
        //   position: absolute;
        //   left: 0.08rem;
        //   top: 0.08rem;
        //   width: 100%;
        //   height: 100%;
        //   border: 1px solid #B285ED;
        // }
      }
      .btn-receive1 {
        width: 2.8rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 00.28rem;
        background-color: rgba(190, 190, 190, 0.8);
        border-radius: 0.08rem;
        color: #fff;
      }
    }
  }
}
</style>
